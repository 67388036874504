import React from 'react';
import { Section } from '../components';
import Booking from '../utils/booking';

function BookingSection() {
  return (
    <Section>
      <Section.Container>
        <Section.Caption>our special offer</Section.Caption>
        <Section.Title>
          Book a no-obligation FREE inspection on us
        </Section.Title>
        <Section.Content direction={'column'}>
          <Section.Group align={'flex-start'} padding={'0 0 7rem 0'} md>
            <Section.Group>
              <Section.ListNumber>1</Section.ListNumber>
              <Section.ListText>
                Choose an available date and time that is convenient for you,
                submit the below form, and we'll take care of the rest.
              </Section.ListText>
            </Section.Group>
            <Section.Group justify={'flex-end'}>
              <Section.ListNumber>2</Section.ListNumber>
              <Section.ListText>
                One of our professional exterminating experts will come to your
                home or office in the greater St. Louis area, and solve all your
                pest problems
              </Section.ListText>
            </Section.Group>
          </Section.Group>
          <Booking />
        </Section.Content>
      </Section.Container>
    </Section>
  );
}

export default BookingSection;
