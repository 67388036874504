import React from 'react';

import Layout from '../layouts/page-layout';
import SEO from '../utils/seo';
import Hero from '../containers/heroContainer';
import AboutContainer from '../containers/aboutContainer';
import ServicesContainer from '../containers/servicesContainer';
import BookingSection from '../containers/bookingSection';

const IndexPage = () => (
  <Layout fixed={true}>
    <SEO
      title="Home"
      description="Unitech Pest and Bed Bug Services is a full service, professional pest management company located in St. Louis, Missouri.  We stand prepared to eliminate unwanted pests in your home or business."
    />
    <Hero />
    <AboutContainer />
    <ServicesContainer />
    <BookingSection />
  </Layout>
);

export default IndexPage;
