import React from 'react';
import { Card, Section } from '../components';
import { graphql, useStaticQuery } from 'gatsby';

function ServicesContainer() {
  const data = useStaticQuery(graphql`
    query {
      services: contentfulServices {
        caption
        title
      }
      cards: allContentfulCards {
        edges {
          node {
            id
            image {
              fluid(maxWidth: 390, quality: 95) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            title
            button
            buttonLink
            description {
              description
            }
          }
        }
      }
    }
  `);

  return (
    <Section id="solutions">
      <Section.Container>
        <Section.Caption>{data.services.caption}</Section.Caption>
        <Section.Title>{data.services.title}</Section.Title>
        <Section.Content>
          <Section.Grid>
            {data.cards.edges.map(
              ({
                node: { id, image, title, button, buttonLink, description },
              }) => {
                return (
                  <Card key={id}>
                    <Card.Image fluid={image.fluid} alt={title} />
                    <Card.Title>{title}</Card.Title>
                    <Card.Description>
                      {description.description}
                    </Card.Description>
                    <Card.Button to={buttonLink}>{button}</Card.Button>
                  </Card>
                );
              }
            )}
          </Section.Grid>
        </Section.Content>
      </Section.Container>
    </Section>
  );
}

export default ServicesContainer;
