import React from 'react';
import { Section } from '../components';
import { graphql, useStaticQuery } from 'gatsby';

function AboutContainer() {
  // Need to add all the data into Contentful
  // Need to query the data from Contentful for this section
  const data = useStaticQuery(graphql`
    query {
      aboutUs: file(relativePath: { eq: "about_us.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  // Need to make the section dynamic based on Contentful data
  return (
    <Section>
      <Section.Container>
        <Section.Caption>let us introduce ourselves</Section.Caption>
        <Section.Title>
          For 12 years we get rid of what's bugging you
        </Section.Title>
        <Section.Content>
          <Section.Pane left>
            <Section.Text>
              Unitech Pest and Bed Bug Services is a full service, professional
              pest management company located in St. Louis, Missouri. We stand
              prepared to eliminate unwanted pests in your home or business. Our
              fast, curteous and professional staff will listen to your concerns
              and promptly answer any questions you have regarding our methods
              of pest removal and the costs associated with it.
            </Section.Text>
            <Section.Text>
              Whether it is ants, roaches, bed bugs, termites, or rodents that
              are giving you problems, Unitech Pest and Bed Bug Services has an
              extermination plan that's right for you.
            </Section.Text>
            <Section.Group>
              <Section.Stats>
                <Section.StatsHead>1100+</Section.StatsHead>
                <Section.StatsBody>Clients</Section.StatsBody>
              </Section.Stats>
              <Section.Stats>
                <Section.StatsHead>3000+</Section.StatsHead>
                <Section.StatsBody>Jobs Done</Section.StatsBody>
              </Section.Stats>
            </Section.Group>
            <Section.Button to="/about">Our Commitment</Section.Button>
          </Section.Pane>
          <Section.Pane right>
            <Section.Picture
              fluid={data.aboutUs.childImageSharp.fluid}
              alt="Personal id with data identification"
            />
          </Section.Pane>
        </Section.Content>
      </Section.Container>
    </Section>
  );
}

export default AboutContainer;
