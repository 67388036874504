import React, { useState } from 'react';

import { Hero } from '../components';
import Snapshot from '../images/video_snapshot.png';
import { graphql, useStaticQuery } from 'gatsby';

import video from '../videos/Testimonial.mp4';
const HeroSection = () => {
  const data = useStaticQuery(graphql`
    query {
      heroSection: contentfulHeroSection {
        caption
        firstSubTitle
        title
        secondSubTitle
        firstButtonText
        firstButtonLink
        secondButtonText
        secondButtonLink
      }
    }
  `);

  const [showVideo, setShowVideo] = useState(false);
  const videoSrc = video;

  return (
    <Hero>
      <Hero.Container>
        <Hero.LeftSection>
          <Hero.LeftSectionWelcome>
            {data.heroSection.caption}
          </Hero.LeftSectionWelcome>
          <Hero.LeftSectionSubHeading>
            {data.heroSection.firstSubTitle}
          </Hero.LeftSectionSubHeading>
          <Hero.LeftSectionHeading>
            {data.heroSection.title}
          </Hero.LeftSectionHeading>
          <Hero.LeftSectionSubHeading>
            {data.heroSection.secondSubTitle}
          </Hero.LeftSectionSubHeading>
          <Hero.ButtonContainer>
            <Hero.Button to={data.heroSection.firstButtonLink}>
              {data.heroSection.firstButtonText}
            </Hero.Button>
            <Hero.Button to={data.heroSection.secondButtonLink} white="true">
              {data.heroSection.secondButtonText}
            </Hero.Button>
          </Hero.ButtonContainer>
        </Hero.LeftSection>
        <Hero.RightSection>
          <Hero.Video snapshot={Snapshot}>
            <Hero.PlayButton onClick={() => setShowVideo(true)} />
          </Hero.Video>
          <Hero.VideoDisplay display={showVideo ? 'block' : 'none'}>
            <Hero.VideoClose onClick={() => setShowVideo(false)}>
              Close
            </Hero.VideoClose>
            {/*texst*/}
            <Hero.Player controls>
              <source src={videoSrc} type="video/mp4" />
            </Hero.Player>
          </Hero.VideoDisplay>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </Hero.RightSection>
      </Hero.Container>
    </Hero>
  );
};
export default HeroSection;
